import { PaperProps } from "../components/Paper";
import { sanitizeDate, sanitizeRelevance } from "./apiUtils";
import { convertRelevance } from "./relevanceUtils";

// build paper data (like digest) from response
// this function is used in DigestPage, SearchPage, ConferencePapersPage,
// SemanticSearch, InteractionsPage, TrendingPage, BookmarksPage, CollectionsPage etc.
export default function responseSetPaperProps(response: any, searchIn: string[] = []) {
  const digest: PaperProps[] = [];
  response.digest_df.map((df_elem: any) => {
    const paper: PaperProps = {
      paperId: df_elem.paper_id,
      title: df_elem.title,
      authors: df_elem.authors,
      submittedDate: sanitizeDate(df_elem.publication_date),
      bookmarkDate: (df_elem.hasOwnProperty('bookmark_date')) ? sanitizeDate(df_elem.bookmark_date) : undefined,
      dateVenue: df_elem.display_venue,
      conferenceName: df_elem.conference,
      relevance: sanitizeRelevance(convertRelevance(df_elem.ranking_score)),
      read: Boolean(df_elem.read),
      githubLink: df_elem.project_link,
      arxivLink: df_elem.url,
      rating: df_elem.rating,
      mainText: df_elem.abstract,
      colour: df_elem.color,
      bookmarked: Boolean(df_elem.bookmarked),
      is_cached: df_elem.is_cached,
      is_cached_filename: df_elem.is_cached_filename,
      twitterShareLink: df_elem.url_for_twitter,
      useScholarInboxPdfServer: response.use_scholar_inbox_pdf_server,
      source: df_elem.source,
      conf_id: df_elem.conf_id,
      conference_year: df_elem.conference_year,
      semantic_scholar_id: df_elem.semantic_scholar_id,
      paperHTMLLink: df_elem.html_link,
      arxivId: df_elem.arxiv_id,
      inferredHighlightStartIndex: df_elem.start_idx,
      inferredHighlightEndIndex: df_elem.end_idx,
      user_paper_collections: df_elem.user_paper_collections,
      similarity: df_elem.similarity,
      similarity_color: df_elem.similarity_color,
      searchIn: searchIn,
      inferredHighlights: df_elem.inferredHighlights,
      totalLikes: df_elem.total_likes,
      totalReads: df_elem.total_read,
      teaser_figures: df_elem.teaser_figures,
      ssAbstractHighlightDetails: df_elem.ssAbstractHighlightInfo,
      ssTitleHighlightDetails: df_elem.ssTitleHighlightInfo,
      category: df_elem.category,
      search_in_planner: df_elem.search_in_planner,
      citations: df_elem.citations,
      author_details: df_elem.author_details,
      poster_tag: df_elem.poster_tag,
    }
    digest.push(paper);
  })
  return digest;
}